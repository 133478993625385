.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gridView2 {
  padding: 20px 10px 10px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 7px 3px 7px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.gridView2 img {
  border-radius: 7px;
}
.gridView2h5 {
  font-size: 23px;
  padding-left: 2px;
  /* color: #c70303; */
  color: black;
  margin-left: 10px;
  background-color: #eee;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.gridView2p {
  margin-left: 10px;
  margin-bottom: 25px;
}
.p-10 {
  padding-left: 10px;
}
.bgGray {
  background-color: #eeeeee;
}
.DetailBtn {
  display: inline-block;
  background-color: #c70303;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.BidAmountField{
  -webkit-align-items:none !important;
}
