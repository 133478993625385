body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(248, 249, 250);
}

.MuiDataGrid-columnSeparator,
.MuiDataGrid-columnHeader:hover .MuiDataGrid-menuIcon {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888 !important;
}

.custom-cell-class {
  color: rgb(99, 101, 120);
  font-weight: 300;
  padding: 7px !important;
}

.css-10rgf7a {
  position: absolute !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 30px !important;
  width: 29px !important;
  background: #fff;
  color: black;
  border-radius: 6px;
}

.image-gallery-slide .image-gallery-image {
  /* height: 300px !important; */
  border-radius: 0px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  border-radius: 0px !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border-radius: 0px !important;
}

.mainBox {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.49);
  border-radius: 10px;
  padding: 10px;
}

.MuiDataGrid-toolbarContainer {
  justify-content: flex-end !important;
  padding: 15px !important;
}

.auctionPage .MuiListItem-dense {
  padding: 0px 13px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.etdInput {
  width: 96% !important;
  height: 50px;
  background-color: #f6f6f6 !important;
  border: 0.2px solid gray;
  border-radius: 5px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 14px;
}
.dateInput {
  width: 100% !important;
  height: 50px;
  background-color: #f6f6f6 !important;
  border: 0.2px solid gray;
  border-radius: 5px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 14px;
}
.reportDateInput {
  width: 90% !important;
  height: 50px;
  background-color: #f6f6f6 !important;
  border: 0.2px solid gray;
  border-radius: 5px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 14px;
}

.invText {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  color: #cc071a;
  cursor: pointer;
  border-radius: 5px;
  text-decoration-line: none;
}
.invText:hover {
  text-decoration-line: underline;
}
.selectInputStyle {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  border-color: 0.2px solid gray;
}
.adminBackground {
  background-image: url("/src/assets/images/backImage1.png"),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100%;
  position: absolute;
}

.css-1eo27ty-MuiContainer-root {
  max-width: 100% !important;
}
.MuiDataGrid-columnHeaders {
  background-color: white;
}
.backImg {
  background-color: #151937;
  background-image: url(/src/assets/images/backImage2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 103vh;
}
.backImgSignUp {
  background-color: #151937;
  background-image: url(/src/assets/images/backImage2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 30px;
  /* height: 103vh; */
}
.css-ewdv3l {
  background-color: rgb(248, 249, 250) !important;
  border: 1px solid rgb(248, 249, 250) !important;
  border-right: 1px solid #ccc !important;
  height: 98vh;
  border-style: dashed !important;
}
.ps-menu-button {
  background-color: rgb(248, 249, 250) !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  /* width: 250px !important; */
  padding: 10px !important;
}
.css-1gxmtlq-MuiPaper-root-MuiGrid-root {
  box-shadow: none !important;
  background-color: #eee !important;
  border: 1px solid #ccc;
  border-style: dashed;
}
.css-1xhvexn {
  padding: 0 !important;
}
#ymHeader {
  width: 100% !important;
}
#findmonth {
  display: none;
}
.chassisStyle {
  width: 100%;
  text-align: center;
}
